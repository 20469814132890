<template>
  <div class="right-section ">
    <div class="d-flex flex-column" style="width: 400px">
      <div class="feed-body">
        <div style="width: 100%">
          <v-btn
            @click="goToCreateBlog"
            v-if="profileType == 'Agency' && $route.name != 'create-blog'"
            class="mt-5 ml-3"
            >Create Blog</v-btn
          >
        </div>
        <div class="pb-5 pt-2">
          <div style="width: 100%; flex: inherit;" class="ma-0 pa-0">
            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular indeterminate color="amber" class="mt-15" />
            </div>
            <div
              v-for="blog in filteredBlogs"
              :key="blog._id"
              style="padding-right: 20px; padding-right: 20px;"
              class="mt-3"
            >
              <blog-small-card :blog="blog" style="width: 100%" />
            </div>
          </div>
        </div>
        <v-snackbar v-model="snackbar">
          {{ errorMessage }}
        </v-snackbar>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BlogSmallCard from "./BlogSmallCard.vue";
export default {
  components: { BlogSmallCard },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      filteredBlogs: [],
    };
  },
  methods: {
    ...mapActions("blog", {
      fetchAllBlogs: "fetchAllBlogs",
      loadMore: "loadMoreEvents",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadMoreEvents() {
      this.loadingMore = true;
      this.loadMore({ skip: this.events.length })
        .then(() => {
          setTimeout(() => {
            this.loadingMore = false;
          }, 2500);
        })
        .catch((error) => {
          this.loadingMore = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    goToCreateBlog() {
      this.$router.push({ name: "create-blog" });
    },
  },
  computed: {
    ...mapState("auth", {
      profileType: "type",
    }),
    ...mapGetters("blog", {
      blogs: "getAllBlogs",
    }),
  },
  watch: {
    blogs(newValue) {
      if (this.$route.query && this.$route.query._id) {
        this.filteredBlogs = newValue.filter(
          (b) => b._id != this.$route.query._id
        );
      } else {
        this.filteredBlogs = newValue;
      }
    },
    "$route.query._id": function(newValue) {
      if (newValue) {
        this.filteredBlogs = this.blogs.filter((b) => b._id != newValue);
      } else {
        this.filteredBlogs = this.blogs;
      }
    },
  },
  mounted() {
    this.loading = true;
    this.loadCompleted = 0;
    this.fetchAllBlogs()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped>
.right-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 56px;
  min-width: 400px;
  max-width: 400px;
}
.right-section:hover {
  overflow-y: auto;
}
.right-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.right-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.right-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.right-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
